<template>
  <v-container fluid class="primary">
    <div class="w-100">
      <v-row>
        <v-col cols="12" sm="6" md="3">
          <v-img
            class="ml-auto mr-auto  ml-sm-0 mr-sm-auto"
            width="175"
            height="auto"
            src="/img_layout/footer/click_coop_white.png"
          ></v-img>
        </v-col>
        <v-col
          v-if="isDesktop"
          class="d-flex align-start justify-space-between"
          cols="12"
          sm="6"
          md="3"
        >
          <div v-for="(link, index) in links" :key="link.templateNavItemId">
            <h5 class="white--text mb-3">{{ link.name }}</h5>

            <div
              v-for="child in link.templateNavItems"
              :key="child.templateNavItemId"
              class="py-3"
            >
              <a
                v-if="child.itemUrl && child.itemUrl !== ''"
                :aria-label="linkName(child)"
                :title="linkName(child)"
                :id="`footer-menu-${child.templateNavItemId}`"
                :href="child.itemUrl || '#'"
                class=" white--text text-decoration-none"
                :class="[child.classStyle, { 'no-evt': index == 2 }]"
                :target="child.target || '_self'"
                @click="onLinkClick"
              >
                {{ child.name }}
              </a>
              <span
                v-else
                :key="child.templateNavItemId"
                class=" white--text text-decoration-none"
                :class="child.classStyle"
                >{{ child.name }}</span
              >
            </div>
          </div>
        </v-col>
        <v-col v-else>
          <v-expansion-panels accordion flat tile :focusable="selectedTab">
            <v-expansion-panel
              v-for="link in links"
              :key="link.templateNavItemId"
            >
              <v-expansion-panel-header class="white--text">
                {{ link.name }}
                <template v-slot:actions>
                  <v-icon color="white">
                    $chevronDown
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content
                class="py-3"
                v-for="item in link.templateNavItems"
                :key="item.templateNavItemId"
              >
                <a
                  class="white--text text-decoration-none"
                  :id="item.templateNavItemId"
                  :target="item.target"
                  @click="clicked"
                  :href="item.itemUrl"
                >
                  <span class="px-3 white--text" :class="item.classes">{{
                    item.name
                  }}</span>
                </a>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col
          class="d-flex flex-column align-center justify-center"
          cols="12"
          sm="6"
          md="3"
        >
          <a
            target="_blank"
            href="https://www.coop.it/richiamo-prodotti"
            class="footer-cta d-flex align-center justify-center text-decoration-none w-100 py-3 rounded-pill secondary--text"
            outlined
            depressed
          >
            <span class="font-weight-bold text-uppercase">
              {{ $t("footer.ecommerce.reclaimProducts") }}</span
            >
          </a>
          <a
            target="_blank"
            href=""
            class="mt-3 footer-cta d-flex align-center justify-center text-decoration-none w-100 py-3 rounded-pill secondary--text"
            outlined
            depressed
          >
            <span class="font-weight-bold text-uppercase">
              {{ $t("footer.ecommerce.contactUs") }}</span
            >
          </a>
        </v-col>
        <v-col class="d-flex flex-column" cols="12" sm="6" md="3">
          <div class="d-flex flex-column">
            <h5 class="white--text">{{ $t("footer.downloadApp.title") }}</h5>
            <div class="d-flex align-center justify-space-btween">
              <a
                :aria-label="
                  $t('footer.ecommerce.downloadApp.click&coop.link.ios.title')
                "
                target="_blank"
                :href="
                  $t('footer.ecommerce.downloadApp.click&coop.link.ios.link')
                "
              >
                <img
                  :alt="
                    $t('footer.ecommerce.downloadApp.click&coop.link.ios.title')
                  "
                  class="px-1"
                  width="120"
                  height="auto"
                  contain
                  src="/img_layout/footer/app-store.png"
                />
              </a>
              <a
                :aria-label="
                  $t(
                    'footer.ecommerce.downloadApp.click&coop.link.android.title'
                  )
                "
                target="_blank"
                :href="
                  $t(
                    'footer.ecommerce.downloadApp.click&coop.link.android.link'
                  )
                "
              >
                <img
                  :alt="
                    $t(
                      'footer.ecommerce.downloadApp.click&coop.link.android.title'
                    )
                  "
                  class="px-1"
                  width="120"
                  height="auto"
                  contain
                  src="/img_layout/footer/google-play.png"
                />
              </a>
            </div>
          </div>
          <div class="d-flex flex-column">
            <h5 class="white--text">{{ $t("footer.payment") }}</h5>
            <div class="d-flex align-center">
              <img
                class="mr-2"
                v-for="(item, idx) in payments"
                :key="idx"
                :width="item.width"
                height="30"
                :aria-label="item.name"
                :src="`/credit-card/${item.icon}.png`"
                :alt="`Immagine ${item.icon}`"
              />
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="my-3 d-flex flex-wrap justify-center align-center">
      <span
        class="my-3 my-md-0 white--text"
        v-html="$t('footer.ecommerce.copyright')"
      ></span>
      <span
        class="my-3 my-md-0  d-flex align-center white--text"
        v-html="$t('footer.ecommerce.extras')"
      ></span>
    </div>
    <small
      class="my-3 text-center d-block white--text"
      v-html="$t('footer.version', [version])"
    ></small>
  </v-container>
</template>
<style lang="scss">
.ecommerce footer .v-expansion-panel {
  background-color: var(--v-primary-base) !important;
}
.ecommerce footer a {
  color: #ffffff;
}
.footer {
  &-cta {
    border: 1px solid var(--v-secondary-base);
    background-color: #ffffff;
  }
}
</style>
<script>
import clickHandler from "@/commons/mixins/clickHandler";
//import get from "lodash/get";
import { mapState } from "vuex";
export default {
  name: "FooterShop",
  mixins: [clickHandler],
  props: {
    links: { type: Array, required: true },
    links2: { type: Array, required: false },
    applicationConfig: { type: Object, required: false }
  },
  data() {
    return {
      payments: [
        {
          icon: "mastercard-curved-64px",
          name: "Pagamento mastercard",
          width: 47
        },
        {
          icon: "visa-curved-64px",
          name: "Pagamento Visa",
          width: 47
        },
        {
          icon: "paypal-curved-64px",
          name: "Pagamento Paypal",
          width: 47
        }
        // {
        //   icon: "amex-curved-64px",
        //   name: "Pagamento Amex",
        //   width: 47
        // },
        // {
        //   icon: "satispay",
        //   name: "Pagamento Satispay",
        //   width: 97
        // }
      ],
      selectedTab: null,
      panel: []
    };
  },
  computed: {
    ...mapState({
      language: ({ app }) => app.language
    }),
    version() {
      return global.config.version;
    },
    logoSrc() {
      return `/logo/logo-${this.language || "it"}.svg`;
    },
    // logoDesktop() {
    //   return get(
    //     this.applicationConfig,
    //     "metaData.template_model.LOGO_DESKTOP"
    //   );
    // },
    isDesktop() {
      return this.$vuetify.breakpoint.smAndUp;
    }
    // logoMobile() {
    //   return get(this.applicationConfig, "metaData.template_model.LOGO_MOBILE");
    // }
  },
  methods: {
    linkName(child) {
      return child?.name.length > 1 ? child.name : "Vai al sito";
    },
    onLinkClick(e) {
      var url = e.currentTarget.href;
      let fileName = "";
      this.$emit("close");
      this.$nextTick(() => {
        if (
          this.$platform_is_cordova &&
          e.currentTarget.href.endsWith(".pdf")
        ) {
          fileName = url.substring(url.lastIndexOf("/") + 1, url.length);
          fileName = fileName.substring(0, fileName.length - 4);
          this.handleReceiptDownload(url, fileName);
        } else if (e.target.className.includes("cookie-widget-trigger")) {
          if (window.Cookiebot) {
            window.Cookiebot.show();
          }
        } else {
          this.clicked(e);
        }
      });
    }
  }
};
</script>
